import React from 'react';
import styled from 'styled-components';
import { ICONS_BY_DAY } from './shared';

const ListItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 7px;
`;

const MainInfo = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
`;

const BarName = styled.a`
    color: black;
`;

const NoteText = styled.span`
    font-weight: bold;
    font-size: 13px;
`;

const AdditionalInfo = styled.div`
    font-style: italic;
    font-size: 13px;
    color: gray;
`;

export function ListItem({ triviaEvent }) {
    const {
        barName,
        time,
        day,
        notes,
        eventType,
        company,
        streetAddress,
        barUrl,
    } = triviaEvent;

    const DayIcon = ICONS_BY_DAY[day];

    const title = `Trivia at ${barName} on ${day} at ${time}! ${
        notes && '**' + notes
    }`;

    const eventTypeToDisplay =
        company === 'Geeks Who Drink' ? ` (${eventType})` : '';

    const additionalInfo = `${company}${eventTypeToDisplay} at ${streetAddress}`;

    return (
        <ListItemContainer
            key={barName}
            title={title}
        >
            <MainInfo>
                <BarName
                    href={barUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    {barName}
                </BarName>
                <DayIcon
                    width={20}
                    height={20}
                />{' '}
                {time}
            </MainInfo>
            {notes && <NoteText>**{notes}</NoteText>}
            <AdditionalInfo>{additionalInfo}</AdditionalInfo>
        </ListItemContainer>
    );
}
