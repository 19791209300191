import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ErrorBoundary } from 'react-error-boundary';
import Error from './Error';
import { ListView } from './ListView';
import { MapView } from './MapView';
import { CSV_URL, VIEWS } from '../config/constants';
import { parseCSV } from '../utils/parseCSV';
import { useSearchParamsState } from '../hooks/useSearchParamState';

const ContentHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 5px 200px;
    text-align: center;
    @media screen and (max-width: 1200px) {
        padding: 5px 10px;
    }
`;

const NumberResults = styled.div`
    font-weight: bold;
`;

const TabContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Tab = styled.div`
    cursor: pointer;
    padding: 0 5px;
    color: gray;
    ${({ selected }) => selected && 'font-weight: bold;'}
    ${({ selected }) => selected && 'text-decoration: underline;'}
    ${({ selected }) => selected && 'color: black;'}
`;

export default function WhereToPlay() {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [triviaEvents, setTriviaEvents] = useState([]);
    const [view, setView] = useSearchParamsState('view', VIEWS.MAP);

    useEffect(() => {
        fetchCSVData();
    }, []);

    const fetchCSVData = () => {
        setLoading(true);
        axios
            .get(CSV_URL)
            .then((response) => {
                const parsedCsvData = parseCSV(response.data);
                setTriviaEvents(parsedCsvData);
                setLoading(false);
                setLoaded(true);
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
                setLoading(false);
                setLoaded(true);
            });
    };

    const numResultsString = triviaEvents.length ? triviaEvents.length : '';

    return (
        <div>
            <ContentHeader>
                <div></div>
                <NumberResults
                    title={`${numResultsString} Results for weekly trivia nights in Austin, TX`}
                >
                    {numResultsString} Results
                </NumberResults>
                <TabContainer>
                    <Tab
                        role='tab'
                        aria-selected={view === VIEWS.MAP}
                        selected={view === VIEWS.MAP}
                        aria-controls='tabpanel-map'
                        id='map'
                        onClick={() => setView(VIEWS.MAP)}
                    >
                        Map
                    </Tab>
                    <div>|</div>
                    <Tab
                        role='tab'
                        aria-selected={view === VIEWS.LIST}
                        selected={view === VIEWS.LIST}
                        aria-controls='tabpanel-list'
                        id='list'
                        onClick={() => setView(VIEWS.LIST)}
                    >
                        List
                    </Tab>
                </TabContainer>
            </ContentHeader>
            {view === VIEWS.MAP ? (
                <ErrorBoundary FallbackComponent={Error}>
                    <MapView />
                </ErrorBoundary>
            ) : (
                <ErrorBoundary FallbackComponent={Error}>
                    <ListView
                        loading={loading}
                        loaded={loaded}
                        triviaEvents={triviaEvents}
                    />
                </ErrorBoundary>
            )}
        </div>
    );
}
