import { useSearchParams } from 'react-router-dom';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { VIEWS, DAY_FILTERS } from '../config/constants';

const validateAcquired = (paramName, acquiredValue) => {
    if (paramName === 'view') return VIEWS[acquiredValue];
    if (paramName === 'day') {
        return DAY_FILTERS.includes(acquiredValue) ? acquiredValue : null;
    }
    return acquiredValue;
};

export function useSearchParamsState(searchParamName, defaultValue) {
    const [searchParams, setSearchParams] = useSearchParams();

    const acquiredSearchParam = searchParams.get(searchParamName);
    const searchParamsState =
        validateAcquired(searchParamName, acquiredSearchParam) ?? defaultValue;

    const setSearchParamsState = (newState) => {
        const next = Object.assign(
            {},
            [...searchParams.entries()].reduce(
                (o, [key, value]) => ({ ...o, [key]: value }),
                {}
            ),
            { [searchParamName]: newState }
        );

        setSearchParams(omitBy(next, (x) => x === '' || isNil(x)));
    };
    return [searchParamsState, setSearchParamsState];
}
