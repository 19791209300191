import { Helmet, HelmetProvider } from 'react-helmet-async';
import styled from 'styled-components';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import WhereToPlay from '../components/WhereToPlay';
import { COLORS } from '../config/theme';
import logo from '../icons/logo.png';

const Navbar = styled.header`
    display: grid;
    grid-template-columns: 3fr 10fr 3fr;
    column-gap: 5px;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
    font-weight: 300;
    @media screen and (max-width: 1200px) {
        grid-template-columns: max-content 1fr;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 7px;
    font-size: 24px;
    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }
`;

const RightHeaderContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Footer = styled.div`
    display: flex;
    flex-flow: column;
    row-gap: 10px;
    text-align: center;
    padding: 25px;
`;

const LastUpdatedText = styled.div`
    text-align: center;
    font-size: 13px;
    color: gray;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 5px;
`;

const StyledButton = styled.button`
    background-color: black;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
`;

const StyledLink = styled.a`
    font-size: 13px;
    color: ${COLORS.blue};
    width: fit-content;
    margin: auto;
`;

export const Home = () => {
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>
                        AtxTrivia.info | Where to Play Trivia in Austin, TX
                    </title>
                    <meta
                        name='description'
                        content='Full list of bars hosting weekly trivia nights in Austin, TX. Check out the map and find trivia near you. Trivia in East Austin, South Austin, Downtown, Cedar Park, Leander, Round Rock, Pflugerville, Lakeway, and Georgetown. Trivia on Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, and Sunday.'
                    />
                </Helmet>
            </HelmetProvider>
            <Navbar>
                <LogoContainer>
                    <img
                        src={logo}
                        style={{ height: '38px', width: 'auto' }}
                        alt='(Einstein with cowboy hat) ATXTrivia.info logo'
                    />
                </LogoContainer>
                <RightHeaderContainer />
            </Navbar>
            <WhereToPlay />
            <Footer>
                <ButtonContainer>
                    <a
                        href='https://docs.google.com/forms/d/e/1FAIpQLSf96ZZd10M4n7Ob3_HhKi8SE75AiCfykREUDcYmQuX2mxoz7Q/viewform?usp=pp_url'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <StyledButton>Want trivia at your bar?</StyledButton>
                    </a>
                    <a
                        href='https://docs.google.com/forms/d/e/1FAIpQLSeWJhS9XRd-xEacK2iVrqV7KFxQQhjhY36USyvCdzC6cLIc8w/viewform?usp=pp_url'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <StyledButton>Looking to host?</StyledButton>
                    </a>
                </ButtonContainer>
                <StyledLink
                    href='https://docs.google.com/forms/d/e/1FAIpQLSdljbjQv33rSOPU8vxVSF_PjwuoXjTqb7ZvuISJkZ-sghVTPg/viewform?usp=pp_url'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Don't see your trivia? Let me know!
                </StyledLink>
                <LastUpdatedText>Last Updated: October 2024</LastUpdatedText>
            </Footer>
            <SpeedInsights />
            <Analytics />
        </div>
    );
};
