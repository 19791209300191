import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../icons/SearchIcon.svg';

const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    column-gap: 5px;
`;

const StyledInput = styled.input`
    width: 175px;
`;

export function Search({ value, handleChange }) {
    return (
        <SearchContainer>
            Search:
            <StyledInput
                type='search'
                id='search'
                name='search'
                onChange={(e) => handleChange(e.target.value)}
                value={value || ''}
                placeholder='Find weekly trivia near you'
            />
            <SearchIcon
                width={18}
                height={18}
            />
        </SearchContainer>
    );
}
