import React from 'react';
import { ContentContainer } from './shared';

export const MapView = () => {
    return (
        <ContentContainer title='Map of Trivia in Austin, TX'>
            <iframe
                title='Map of Trivia in Austin, TX'
                src='https://www.google.com/maps/d/u/0/embed?mid=1wPtjnG8jPH6pWP7m2omDJDHgwpzaABo&ehbc=2E312F&noprof=1'
                width='100%'
                height='100%'
            ></iframe>
        </ContentContainer>
    );
};
