import React, { useCallback, useMemo } from 'react';
import { ContentContainer } from './shared';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import { ListSection } from './ListSection';
import { ListFilters } from './ListFilters';
import { Search } from './Search';
import { filterEventsByDay, filterEventsBySearchTerm } from './shared';
import { useSearchParamsState } from '../hooks/useSearchParamState';

const ListContainer = styled(ContentContainer)`
    overflow: auto;
    height: calc(100vh - 300px);
    @media screen and (max-width: 1200px) {
        height: calc(100vh - 375px);
    }
`;

const CenteredMessage = styled.div`
    text-align: center;
    max-width: 625px;
    margin: auto;
    padding: 50px 0;
`;

const DEFAULT_EVENTS_BY_DAY = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
};

export const ListView = ({ triviaEvents, loading, loaded }) => {
    const [selectedDay, setSelectedDay] = useSearchParamsState('day', '');
    const [searchTerm, setSearchTerm] = useSearchParamsState('term', '');

    const eventsByNeighborhood = useMemo(
        () => groupBy(triviaEvents, 'neighborhood'),
        [triviaEvents]
    );

    const eventsByDay = useMemo(
        () => groupBy(triviaEvents, 'day') || DEFAULT_EVENTS_BY_DAY,
        [triviaEvents]
    );

    const filteredEventsAreEmpty = useMemo(
        () =>
            isEmpty(
                filterEventsBySearchTerm(
                    filterEventsByDay(triviaEvents, selectedDay),
                    searchTerm
                )
            ),
        [triviaEvents, selectedDay, searchTerm]
    );

    const appliedFiltersDisplayText = useMemo(() => {
        if (!selectedDay && !searchTerm) return 'no filters.';
        if (!selectedDay) return `"${searchTerm}"`;
        if (!searchTerm) return selectedDay;
        return `${selectedDay} and "${searchTerm}"`;
    }, [selectedDay, searchTerm]);

    const handleClickDay = useCallback(
        (day) => {
            if (selectedDay === day) {
                setSelectedDay('');
            } else {
                setSelectedDay(day);
            }
        },
        [selectedDay, setSelectedDay]
    );

    const neighborhoods = Object.keys(eventsByNeighborhood);

    if (loading || !loaded) {
        return (
            <ContentContainer title='List of Trivia in Austin, TX'>
                <CenteredMessage>Loading...</CenteredMessage>
            </ContentContainer>
        );
    }

    if (triviaEvents.length === 0) {
        return (
            <ContentContainer title='List of Trivia in Austin, TX'>
                <CenteredMessage>
                    Sorry, the list appears empty. There might be trouble
                    connecting to the database. Please try refreshing the page.
                </CenteredMessage>
            </ContentContainer>
        );
    }

    return (
        <div>
            <ListFilters
                eventsByDay={eventsByDay}
                handleClickDay={handleClickDay}
                selectedDay={selectedDay}
            />
            <Search
                value={searchTerm}
                handleChange={setSearchTerm}
            />
            {filteredEventsAreEmpty ? (
                <ListContainer title='List of Trivia in Austin, TX'>
                    <CenteredMessage>
                        {`No results for ${appliedFiltersDisplayText}`}
                    </CenteredMessage>
                </ListContainer>
            ) : (
                <ListContainer title='List of Trivia in Austin, TX'>
                    {neighborhoods.map((neighborhood) => (
                        <ListSection
                            key={neighborhood}
                            neighborhood={neighborhood}
                            triviaEvents={eventsByNeighborhood[neighborhood]}
                            selectedDay={selectedDay}
                            searchTerm={searchTerm}
                        />
                    ))}
                </ListContainer>
            )}
        </div>
    );
};
