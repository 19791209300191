export const CSV_URL =
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vSN1BPtjk_XYQOkr5S783TmMOGOF5FrWgxcVIwoerflCL17tREa1QLQHeqjs76vQoRypy5SAki_ZlSl/pub?gid=0&single=true&output=csv';

export const VIEWS = {
    MAP: 'MAP',
    LIST: 'LIST',
};

export const DAYS = {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
};

export const DAY_GROUPS = {
    Weekend: 'Weekend',
};

export const DAY_FILTERS = [
    DAYS.Monday,
    DAYS.Tuesday,
    DAYS.Wednesday,
    DAYS.Thursday,
    DAY_GROUPS.Weekend,
];

export const WEEKEND_DAYS = new Set([DAYS.Friday, DAYS.Saturday, DAYS.Sunday]);
