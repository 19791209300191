export const COLORS = {
    red: '#9C1017',
    blue: '#67a298',
    tan: '#DACEA3',
    orange: '#EC7F07',
};

export const COLORS_BY_DAY = {
    Monday: COLORS.red,
    Tuesday: COLORS.blue,
    Wednesday: COLORS.tan,
    Thursday: COLORS.orange,
    Weekend: 'black',
};
