import React from 'react';

const Error = () => {
    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            Sorry, something went wrong :/
        </div>
    );
};

export default Error;
