import styled from 'styled-components';
import { DAYS, DAY_GROUPS, WEEKEND_DAYS } from '../config/constants';
import { ReactComponent as MondayIcon } from '../icons/M.svg';
import { ReactComponent as TuesdayIcon } from '../icons/T.svg';
import { ReactComponent as WednesdayIcon } from '../icons/W.svg';
import { ReactComponent as ThursdayIcon } from '../icons/Th.svg';
import { ReactComponent as FridayIcon } from '../icons/F.svg';
import { ReactComponent as SaturdayIcon } from '../icons/Sa.svg';
import { ReactComponent as SundayIcon } from '../icons/Su.svg';

export const ContentContainer = styled.div`
    padding: 0 200px;
    height: calc(100vh - 250px);
    min-width: 200px;
    @media screen and (max-width: 1200px) {
        padding: 0 10px;
        height: calc(100vh - 310px);
    }
`;

export const ICONS_BY_DAY = {
    [DAYS.Monday]: MondayIcon,
    [DAYS.Tuesday]: TuesdayIcon,
    [DAYS.Wednesday]: WednesdayIcon,
    [DAYS.Thursday]: ThursdayIcon,
    [DAYS.Friday]: FridayIcon,
    [DAYS.Saturday]: SaturdayIcon,
    [DAYS.Sunday]: SundayIcon,
};

export const filterEventsBySearchTerm = (events, searchTerm) => {
    const cleanedTerm = searchTerm.toLowerCase().trim();
    if (!searchTerm) return events;
    return events.filter(
        (event) =>
            event.barName.toLowerCase().includes(cleanedTerm) ||
            event.eventType.toLowerCase().includes(cleanedTerm) ||
            event.company.toLowerCase().includes(cleanedTerm) ||
            event.streetAddress.toLowerCase().includes(cleanedTerm)
    );
};

export const filterEventsByDay = (events, day) => {
    if (day === '') {
        return events;
    }
    if (day === DAY_GROUPS.Weekend) {
        return events.filter((event) => WEEKEND_DAYS.has(event.day));
    }
    return events.filter((event) => event.day === day);
};
