import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ListItem } from './ListItem';
import { filterEventsBySearchTerm, filterEventsByDay } from './shared';
import { COLORS } from '../config/theme';

const ListSectionContainer = styled.div`
    text-align: center;
`;

const ListSectionHeader = styled.div`
    padding: 5px;
    font-weight: bold;
    z-index: 99;
    top: 0;
    position: sticky;
    background-color: white;
    width: 100%;
    font-size: 18px;
    font-family: monospace;
    color: ${COLORS.orange};
`;

export function ListSection({
    neighborhood,
    triviaEvents,
    selectedDay,
    searchTerm,
}) {
    const eventsFilteredByDay = useMemo(
        () => filterEventsByDay(triviaEvents, selectedDay),
        [triviaEvents, selectedDay]
    );

    const filteredEvents = useMemo(() => {
        return filterEventsBySearchTerm(eventsFilteredByDay, searchTerm);
    }, [eventsFilteredByDay, searchTerm]);

    return (
        <ListSectionContainer>
            {!!filteredEvents.length && (
                <ListSectionHeader>{`[ ${neighborhood} ]`}</ListSectionHeader>
            )}
            {filteredEvents.map((triviaEvent) => (
                <ListItem
                    key={triviaEvent.barName}
                    triviaEvent={triviaEvent}
                />
            ))}
        </ListSectionContainer>
    );
}
